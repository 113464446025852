.imageContainer{
  position: relative;
  overflow:hidden;
  /* height: 100px; */
}

.card{
  height: 350px;
}

.card-sm{
  height: 250px;
  width: 400px;
  border-color: black;
  border-style: solid;
  box-shadow: 3px 3px rgba(0, 0, 0, 0.2) !important;

}

a:hover{
  text-decoration:none;
}

.navbar-brand:hover{
  color: rgba(255,255,255,.5) !important
}

.album{
  padding-top:10px !important;
}

.filterbox{
  text-align: center;
  /* width: 75%; /* for four filters */ 

  /* background-color: rgb(83, 242, 83); */
  background: rgba(255, 255, 255, 0.3);
  border-color: black;
  border-style: solid;
  box-shadow: 3px 3px rgba(0, 0, 0, 0.2);

  margin: 40px auto 10px;
  /* position: absolute; */
  /* transform: translateY(-50%); */
  /* left: 12.5%; /* for four filters */ 
  left: 31.25%;
  border-radius: 20px;
}

.background-image-hero{
  /* position: absolute; */
  z-index:-1
}

.filter-section-header{
  color:gray;
  font-size:1rem;
  padding-top:10px;
  margin-bottom: 6px;
  font-weight:bold
}

@media (min-width: 768px){
  .filters-li:hover{
    background-color: red;
    color:white;
    cursor: pointer;
  }
  .filterbox{
    width: 37.5%;

  }
}

.mezelfijsbadpic{
  width: 100px;
  height: 100px;
  border-radius: 50px;
}

.buyButton{
  background: white;
  color: green;
  border: 1px solid green;
  padding: 10px 20px;
  /* margin: 20px; */
  /* font-size: 10px; */
  /* font-weight: bold; */
  box-shadow: 0 3px 5px #aaa;

  /* where the magic happens */
   border-radius: 25px /*% 60% 30% 50%; */
}

.buyButton:hover{
  background: green;
  color: white;
}


.filter-section-category{
  margin-bottom:0.25rem;
}

.filters-ul{
list-style:none;
padding-left:0;
}

.filters-li{
  margin-bottom:0.25rem;
  padding: 2px 10px;
  border-radius: 3px;
}

.activeFilter{
  background-color: blue;
  color:white;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity:0.8;
  background-color:white;
  border-radius:10px;
  /* display:none; */
  white-space: nowrap;
  width:0;
  /* padding:0; */
  overflow:hidden;
  transition: width 0.5s ease;
  /* transition: padding 0.5s ease; */
  text-align:center;
  padding:5px 0;

}

.imageContainer:hover .centered {
  /* display:block; */
  width:250px;
  /* padding:5px 10px;  */

}
.why-cool-question{
  padding:0;
  color:green;
}

.card-company-name{
  font-size: 1em;
  text-shadow:-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  color: #B2B1B9;
}

.jumbotron {
  margin-bottom: 0;
  background-color: #fff;
  padding-top: 6rem;



}
@media (min-width: 768px) {
  .jumbotron {
    padding-bottom: 10px; 
    background-size: 100%;

  }
}

@media (max-width: 768px) {
  .jumbotron {
    /* padding-bottom: 320px;  */
  }
}


.jumbotron p:last-child {
  margin-bottom: 0;
}

.jumbotron h1 {
  font-weight: 300;
}

.jumbotron .container {
  max-width: 40rem;
}

footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

footer p {
  margin-bottom: .25rem;
}

a{
      text-decoration-line: none;

}


.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}